@import 'global';

.navbar-brand {

    padding: 5px 15px;

    img {
        max-height: 40px;
    }
}

* {
    font-family: 'Roboto';
}

.navbar-brand img {
    height: 100%;
}

.btn {
    &.btn-primary {
        background-color: $color-green;
        border-color: $color-green;
    }
}

ul.checks {

    list-style: none;
    padding: 0;
    margin: 10px 0;

    li {
        &.bg-balanced {
            background-color: #33CD5F;
            color: #ffffff;
        }

        &.bg-energized {
            background-color: #FFC900;
        }

        &.bg-assertive {
            background-color: #EF473A;
            color: #ffffff;
        }
    }
}
