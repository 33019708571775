// Body
$body-bg: #fff;

// Typography
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$font-family-sans-serif: "Roboto", Helvetica, Arial, sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;


$color-green:       #5b9035;
$color-green-light: #aebe21;
$color-grey:        #aaa9a9;
