// Fonts
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 300;
	src: local('Roboto Light'), local('Roboto-Light'), url(../fonts/roboto/Roboto-Italic.ttf) format('truetype');
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	src: local('Roboto'), local('Roboto-Regular'), url(../fonts/roboto/Roboto-Regular.ttf) format('truetype');
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	src: local('Roboto Bold'), local('Roboto-Bold'), url(../fonts/roboto/Roboto-Bold.ttf) format('truetype');
}

@font-face {
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 300;
	src: local('Roboto Light Italic'), local('Roboto-LightItalic'), url(../fonts/roboto/Roboto-LightItalic.ttf) format('truetype');
}

@font-face {
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 400;
	src: local('Roboto Italic'), local('Roboto-Italic'), url(../fonts/roboto/Roboto-Italic.ttf) format('truetype');
}

@font-face {
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 700;
	src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'), url(../fonts/roboto/Roboto-BoldItalic.ttf) format('truetype');
}

// Variables
@import 'variables';

// Bootstrap
@import "~bootstrap-sass/assets/stylesheets/bootstrap";